import STARCalendar from "../components/Calendar/Calendar";
import Content from "../components/Content/Content";
import Wrapper from "../components/Wrapper/Wrapper";

const CalendarPage = () => {
  return (
    <>
      <Wrapper>
        <Content columns="1" contentWidth="90vw">
          <STARCalendar />
        </Content>
      </Wrapper>
    </>
  );
};

export default CalendarPage;
