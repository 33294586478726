import styled from "styled-components";

export const StyledHero = styled.div`
  width: 100%;
  background-color: ${({ bgColor, theme }) => {
    if (bgColor === "green") return theme.secondaryColorLight;
    if (bgColor === "orange") return theme.novaColorLight;
    if (bgColor === "blue") return theme.galaxyColorLight;
    if (bgColor === "purple") return theme.primaryColor;
    if (bgColor === "lightPurple") return theme.primaryColorLight;
    if (bgColor === "") return theme.contentBackground;
    // return theme.contentBackground;
    return bgColor;
  }};
  background-image: url("${({ bgImage }) => bgImage}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${({ bgPosition }) => bgPosition};

  /* Blend Mode Values /
    normal:       This is default. Sets the blending mode to normal	
    multiply:     Sets the blending mode to multiply	
    screen:       Sets the blending mode to screen	
    overlay:      Sets the blending mode to overlay	
    darken:       Sets the blending mode to darken	
    lighten:      Sets the blending mode to lighten	
    color-dodge:	Sets the blending mode to color-dodge	
    color-burn:   Sets the blending mode to color-burn	
    difference:   Sets the blending mode to difference	
    exclusion:    Sets the blending mode to exclusion	
    hue:          Sets the blending mode to hue	
    saturation:   Sets the blending mode to saturation	
    color:        Sets the blending mode to color	
    luminosity:   Sets the blending mode to luminosity 
  */

  background-blend-mode: ${({ blendMode }) => blendMode};
  padding: 1rem 0;

  & > * {
    padding: ${({ theme }) => theme.pdDefault};
  }
`;

export const HeroGrid = styled.div`
  display: grid;
  max-width: 90%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 6px;

  nav {
    display: flex;
    flex-direction: column;

    a {
      padding-inline: calc(${({ theme }) => theme.pdDefault} * 2);
    }

    :last-child {
      margin-bottom: calc(${({ theme }) => theme.pdDefault} * 2);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fntSerif};
    color: ${({ theme }) => theme.primaryColor};
    padding-inline: calc(${({ theme }) => theme.pdDefault} * 2);
    padding-block: ${({ theme }) => theme.pdDefault};
    font-weight: 700;
  }

  h1 {
    font-size: clamp(1rem, 1.5rem, 1.75rem);
  }

  h2 {
    font-size: clamp(0.9rem, 1.4rem, 1.7rem);
  }

  h3 {
    font-size: clamp(0.8rem, 1.3rem, 1.6rem);
  }

  h4 {
    font-size: clamp(0.7rem, 1.2rem, 1.5rem);
  }

  h5 {
    font-size: clamp(0.6rem, 1.1rem, 1.4rem);
  }

  h6 {
    font-size: clamp(0.5rem, 1rem, 1.3rem);
  }

  p {
    font-family: ${({ theme }) => theme.fntSans};
    margin-bottom: ${({ theme }) => theme.pdDefault};
    padding-inline: calc(${({ theme }) => theme.pdDefault} * 2);
  }

  small {
    font-family: ${({ theme }) => theme.fntSans};
    margin-bottom: ${({ theme }) => theme.pdDefault};
    padding-inline: calc(${({ theme }) => theme.pdDefault} * 2);
  }

  @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    max-width: 75%;
  }
`;

export const HeroGridP = styled.p`
  padding: calc(${({ theme }) => theme.pdDefault} * 2);
  font-family: ${({ theme }) => theme.fntSans};
  line-height: 1.25;

  &::first-line {
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fntSerif};
    color: ${({ theme }) => theme.primaryColor};
    font-size: 1.25rem;
  }
`;

export const HeroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fntSerif};
  font-size: clamp(1.25rem, 5vw + 1rem, 3rem);
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-shadow: 0.125rem 0.125rem 0.125rem ${({ theme }) => theme.lightGray};
  /* -webkit-text-stroke: 0.0275rem ${({ theme }) => theme.white}; */
  letter-spacing: 0.125rem;
  color: ${({ theme }) => theme.primaryColor};
`;
