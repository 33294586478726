import { StyledContent, ContentTitle, ContentBorder } from "./Content.style";

const Content = ({
  title,
  children,
  columns,
  borderColor,
  borderWidth,
  borderStyle,
  borderRadius,
  bgColor,
  clipPath,
  contentWidth,
  showTitle,
  id,
  style,
}) => {
  return (
    <ContentBorder
      borderColor={borderColor}
      borderWidth={borderWidth}
      borderStyle={borderStyle}
      borderRadius={borderRadius}
      bgColor={bgColor}
      clipPath={clipPath}
      showTitle={showTitle}
      style={style}
    >
      <ContentTitle showTitle={showTitle}>{title}</ContentTitle>
      <StyledContent id={id} columns={columns} contentWidth={contentWidth}>
        {children}
      </StyledContent>
    </ContentBorder>
  );
};

export default Content;
