import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  StyledNav,
  Hamburger,
  Logo,
  Menu,
  Title,
} from "../NavBar/NavBar.style";
import STARLogo from "../../images/starLogo.svg";
import { MdClose } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import { MenuList } from "../../utils/MenuList";

const NavBar = () => {
  const title = 'STAR Sacramento'
  const [isOpen, setIsOpen] = useState(false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <StyledNav>
      <Logo>
        <img src={STARLogo} alt="" />
        <span>{title}</span>
      </Logo>
      <Title>{title}</Title>
      <Menu isOpen={isOpen}>
        <ul>
          {MenuList.map((link, index) => (
            <NavLink key={index} to={link.url} exact activeClassName="current">
              <li onClick={handleToggle}>{link.title}</li>
            </NavLink>
          ))}
          <a href="https://accounts.starsacramento.org/contact/" target="_blank" rel="noreferrer"><li>Contact STAR</li></a>
          <a href="https://my.starsacramento.org" target="_blank" rel="noreferrer"><li>My STAR Account</li></a>
          <a href="https://reg.starsacramento.org" target="_blank" rel="noreferrer"><li>Enroll</li></a>
        </ul>
      </Menu>
      <Hamburger onClick={handleToggle}>
        {isOpen ? <MdClose /> : <FiMenu />}
      </Hamburger>
    </StyledNav>
  );
};

export default NavBar;
