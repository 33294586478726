import { StyledHero, HeroGrid, HeroTitle } from "./Hero.style";

const Hero = ({
  bgImage,
  bgColor,
  bgPosition,
  blendMode,
  title,
  children,
  id,
  style
}) => {
  return (
    <StyledHero
      id={id}
      bgImage={bgImage}
      bgColor={bgColor}
      bgPosition={bgPosition}
      blendMode={blendMode}
    >
      <HeroTitle>{title}</HeroTitle>
      <HeroGrid style={style}>{children}</HeroGrid>
    </StyledHero>
  );
};

export default Hero;
