import styled from "styled-components";

const Button = styled.button`
  cursor: pointer;
  padding: ${(props) => {
    if (props.small) return "0.35rem 0.65rem";
    if (props.medium) return "0.5rem 0.75rem";
    return "0.75rem 1rem";
  }};
  font-size: ${(props) => {
    if (props.small) return ".75rem";
    if (props.medium) return ".85rem";
    return "1rem";
  }};
  font-weight: 600;
  text-align: center;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-family: ${({ theme }) => theme.fntSans};
  margin: 0.25rem;
  border-color: ${({ theme }) => theme.primaryColorLight};
  border-style: solid;
  border-width: 3px;
  border-radius: 8px;
  background-color: ${(props) => {
    if (props.green) return props.theme.secondaryColor;
    if (props.orange) return props.theme.novaColor;
    return props.theme.primaryColor;
  }};
  color: ${(props) => {
    if (props.green || props.orange) return props.theme.black;
    return props.theme.white;
  }};
  display: ${(props) => {
    if (props.wide) return "block";
    if (props.hidden) return "none";
    return "inline-block";
  }};
  width: ${(props) => (props.wide ? "100%" : "initial")};

  :focus,
  :hover {
    color: ${({ theme }) => theme.primaryColor};
    box-shadow: 2px 2px 4px 2px ${({ theme }) => theme.lightGray};
    border-color: ${({ theme }) => theme.primaryColor};
    background-color: ${({ theme }) => theme.primaryColorLight};
  }

  :disabled {
    opacity: 0.5;
    background-color: ${({ theme }) => theme.lightGray};
    border-color: ${({ theme }) => theme.primaryColorLight};
    color: ${({ theme }) => theme.black};
  }

  :disabled:hover {
    cursor: default;
    background-color: ${({ theme }) => theme.lightGray};
    box-shadow: none;
  }
`;

export default Button;
