import SPCalendar from "../components/SPCalendar/SPCalendar";
import Content from "../components/Content/Content";
import Wrapper from "../components/Wrapper/Wrapper";

const SPCalendarPage = () => {
  return (
    <>
      <Wrapper id="speu">
        <Content
          titleShow={false}
          columns="1"
          borderColor="orange"
          borderWidth="2px"
          borderStyle="solid"
          borderRadius="0 8px"
          contentWidth="90vw"
        >
          <div>
            <h3>Hover Over A Camp Title To View The Camps Description</h3>
          </div>
        </Content>
      </Wrapper>
      <Wrapper>
        <Content columns="1" contentWidth="90vw">
          <SPCalendar />
        </Content>
      </Wrapper>
    </>
  );
};

export default SPCalendarPage;
