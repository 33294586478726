import styled from "styled-components";

export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.novaColor};
  font-family: ${({ theme }) => theme.fntSans};
  font-weight: 700;
  padding: 0.5rem 0;
  width: 100%;
  color: ${({ theme }) => theme.white};
`;

export const Hamburger = styled.div`
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  margin-right: 1rem;
  z-index: 9999;

  &:hover {
    cursor: pointer;
  }

@media (min-width: ${({ theme }) => theme.bkpntUltra}) {
    display: none;
  }
`;

export const Menu = styled.div`
  position: absolute;
  z-index: 9999;
  background-color: ${({ theme }) => theme.novaColor};
  min-width: 50vw;
  min-height: 50vh;
  top: 0;
  right: 0;
  left: ${({ isOpen }) => (isOpen ? "25vw" : "100vw")};
  opacity: ${({ isOpen }) => (isOpen ? ".95" : "0")};
  transition: left 500ms ease-in, opacity 500ms ease-in;

  ul {
    margin-top: 8vh;
  }

  a {
    display: block;
    text-decoration: none;
    padding: 0.75rem 1rem;
    color: ${({ theme }) => theme.white};
    padding: 0.5rem 1rem;

    &:hover {
      background-color: ${({ theme }) => theme.primaryColor};
      cursor: pointer;
      color: ${({ theme }) => theme.white};
    }
  }

  @media (min-width: ${({ theme }) => theme.bkpntUltra}) {
    // display: block;
    z-index: 0;
    position: relative;
    background-color: transparent;
    min-width: 0;
    min-height: 0;
    left: 0;
    opacity: 1;

    ul {
      display: flex;
      margin-top: 0;
      margin-left: 0;
    }

    a {
      padding: 0.75rem 1rem;
      margin-right: 1rem;
      border: 3px solid ${({ theme }) => theme.primaryColorLight};
      border-radius: 8px;
      background-color: ${({ theme }) => theme.primaryColor};
      color: ${({ theme }) => theme.white};
      font-weight: 500;
      transition: 250ms ease-in;

      &:last-child {
        border: 3px solid ${({ theme }) => theme.primaryColor};
        background-color: ${({ theme }) => theme.primaryColorLight};
        color: ${({ theme }) => theme.primaryColor};
      }

      &:hover {
        cursor: pointer;
        border: 3px solid ${({ theme }) => theme.primaryColor};
        background-color: ${({ theme }) => theme.primaryColorLight};
        color: ${({ theme }) => theme.primaryColor};
      }

      &:last-child:hover {
        cursor: pointer;
        border: 3px solid ${({ theme }) => theme.primaryColorLight};
        background-color: ${({ theme }) => theme.primaryColor};
        color: ${({ theme }) => theme.white};
      }
    }
  }
`;

export const Logo = styled.div`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.primaryColor};
  font-size: 1.25rem;
  display: none;

  @media (min-width: ${({ theme }) => theme.bkpntLarge}) {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    flex: 1;

    img {
      max-width: 10%;
    }

    span {
      margin-left: 1rem;
      text-shadow: -0.05rem -0.05rem 0 ${({ theme }) => theme.white},
        0.05rem -0.05rem 0 ${({ theme }) => theme.white},
        -0.05rem 0.05rem 0 ${({ theme }) => theme.white},
        0.05rem 0.05rem 0 ${({ theme }) => theme.white};
      letter-spacing: 0.125rem;
    }
  }
`;

export const Title = styled.div`
  padding: 1rem 0;
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.primaryColor};
  text-shadow: -0.05rem -0.05rem 0 ${({ theme }) => theme.white},
    0.05rem -0.05rem 0 ${({ theme }) => theme.white},
    -0.05rem 0.05rem 0 ${({ theme }) => theme.white},
    0.05rem 0.05rem 0 ${({ theme }) => theme.white};
  font-size: 1.25rem;
  letter-spacing: 0.15rem;

  @media (min-width: ${({ theme }) => theme.bkpntLarge}) {
    display: none;
  }
`;
