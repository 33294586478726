import styled from "styled-components";

export const CalWrapper = styled.div`
  width: 100%;
  margin-inline: auto;
  font-family: ${({ theme }) => theme.fntSerif};

  .fc-toolbar {
    background-color: ${({ theme }) => theme.novaColor};
    padding: ${({ theme }) => theme.pdDefault};
  }

  .fc-toolbar.fc-header-toolbar {
    margin-bottom: 0;
  }

  .fc-toolbar-title {
    font-family: ${({ theme }) => theme.fntSerif};
    color: ${({ theme }) => theme.white};
    text-shadow: 0.125rem 0.125rem 0.125rem ${({ theme }) => theme.black};
    font-weight: 500;
  }

  .fc-today-button, .fc-button {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColorLight};
    transition: background-color 0.25s ease-out, border-color 0.25s ease-out;
  }

  .fc-today-button:hover, .fc-button:hover {
    background-color: ${({ theme }) => theme.primaryColorLight};
    border-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColor};
  }

  .fc-h-event {
    background-color: ${({ theme }) => theme.primaryColor};
    border-color: ${({ theme }) => theme.primaryColor};
  }
`;
