import styled from "styled-components";

export const StyledContent = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  padding: 1rem;
  font-family: ${({ theme }) => theme.fntSans};
  grid-gap: 1rem;
  margin: 0 auto;
  width: 90vw;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${({ theme }) => theme.fntSerif};
    color: ${({ theme }) => theme.primaryColor};
  }

  h1 {
    font-size: clamp(1rem, 1.5rem, 1.75rem);
  }

  h2 {
    font-size: clamp(0.9rem, 1.4rem, 1.7rem);
  }

  h3 {
    font-size: clamp(0.8rem, 1.3rem, 1.6rem);
  }

  h4 {
    font-size: clamp(0.7rem, 1.2rem, 1.5rem);
  }

  h5 {
    font-size: clamp(0.6rem, 1.1rem, 1.4rem);
  }

  h6 {
    font-size: clamp(0.5rem, 1rem, 1.3rem);
  }

  ol {
    padding-inline: calc(${({ theme }) => theme.pdDefault} * 4);

    & li {
      margin-block: calc(${({ theme }) => theme.pdDefault} * 2);
    }
  }

  .text-align-center {
    text-align: center;
  }

  p {
    font-family: ${({ theme }) => theme.fntSans};
    margin-bottom: ${({ theme }) => theme.pdDefault};

    &[data-emphasis="true"]::first-line {
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.fntSerif};
      color: ${({ theme }) => theme.primaryColor};
      font-size: 1.25rem;
    }
  }

  nav {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: space-evenly;

    a {
      padding: ${({ theme }) => theme.pdDefault};
    }

    :last-child {
      margin-bottom: calc(${({ theme }) => theme.pdDefault} * 2);
    }
  }

  @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
    grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
    width: ${({ contentWidth }) => contentWidth};
  }
`;

export const ContentTitle = styled.div`
  font-family: ${({ theme }) => theme.fntSerif};
  font-size: clamp(1rem, 1.5rem, 1.75rem);
  font-weight: 700;
  color: ${({ theme }) => theme.primaryColor};
  padding: 1rem 1rem 0 1rem;
  display: ${({ showTitle }) => {
    if (showTitle === "true") return "inherit";
    return "none";
  }};
`;

export const ContentBorder = styled.div`
  border-width: ${({ borderWidth }) => borderWidth};
  border-style: ${({ borderStyle }) => borderStyle};
  border-color: ${({ borderColor, theme }) => {
    if (borderColor === "purple") return theme.primaryColor;
    if (borderColor === "green") return theme.secondaryColor;
    if (borderColor === "orange") return theme.novaColor;
    if (borderColor === "blue") return theme.galaxyColor;
    if (borderColor === "") return "transparent";
    return borderColor;
  }};

  /* Border Radius Values: Top Right Bottom Left px or % */
  border-radius: ${({ borderRadius }) => borderRadius};

  /* Background Color Values: HEX RGB RBGA HSL Color Name*/
  background-color: ${({ bgColor }) => bgColor};

  /* Optional Clip Path: https://bennettfeely.com/clippy/ Tread Lightly*/
  clip-path: ${({ clipPath }) => clipPath};

  margin: 1rem;
`;
