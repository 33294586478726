import { StyledQuote, Blockquote, Cite } from "./Quote.style";

const Quote = ({ quote, cite}) => {
  return (
    <StyledQuote>
      <Blockquote>
        {quote}
      </Blockquote>
      <Cite>{cite}</Cite>
    </StyledQuote>
  );
};

export default Quote;
