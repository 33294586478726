import styled from "styled-components";

export const StyledQuote = styled.div`
  padding: calc(${({ theme }) => theme.pdDefault} * 2);
`;

export const Blockquote = styled.blockquote`
  font-family: ${({ theme }) => theme.fntHandB};
  font-size: clamp(0.5rem, 1.25rem, 1.5rem);

  ::before,
  ::after {
    content: '"';
  }
`;

export const Cite = styled.cite`
  font-family: ${({ theme }) => theme.fntSans};
  margin-block: ${({ theme }) => theme.pdDefault};
  display: block;

  ::before {
    content: "--- ";
  }
`;
