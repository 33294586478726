export const MenuList = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Programs",
    url: "/programs",
  },
  {
    title: "Locations",
    url: "/locations",
  },
  {
    title: "About STAR",
    url: "/about-star",
  },
];
