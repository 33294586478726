import { StyledHeader, HeaderTitle } from "./Header.style";

const Header = ({
  bgImage,
  bgColor,
  bgPosition,
  blendMode,
  title,
  children,
  showTitle,
}) => {
  return (
    <>
      <StyledHeader
        bgImage={bgImage}
        bgColor={bgColor}
        bgPosition={bgPosition}
        blendMode={blendMode}
      >
        <HeaderTitle showTitle={showTitle}>{title}</HeaderTitle>
        {children}
      </StyledHeader>
    </>
  );
};

export default Header;
