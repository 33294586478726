import { StyledSection, Title } from "./Section.style";

const Section = ({ titleSize, title, children, id }) => {
  return (
    <StyledSection id={id}>
      <Title titleSize={titleSize}>{title}</Title>
      {children}
    </StyledSection>
  );
};

export default Section;
