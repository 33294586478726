import styled from "styled-components";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export const STARAccordion = styled(Accordion)`
  max-width: 100%;
  margin: 0 auto;
  font-family: ${({ theme }) => theme.fntSans};
  color: ${({ theme }) => theme.white};
  font-weight: 600;
  border-radius: 8px;
  outline: 3px solid ${({ theme }) => theme.primaryColor};
  margin: ${({ theme }) => theme.pdDefault};

  @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
    /* width: 90vw; */
  }
`;

export const STARAccordionItem = styled(AccordionItem)`
  /* max-width: 100%; */
`;

export const STARAccordionItemHeading = styled(AccordionItemHeading)``;

export const STARAccordionItemButton = styled(AccordionItemButton)`
  background-color: ${({ theme }) => theme.primaryColorLight};
  padding: 1rem;
  color: ${({ theme }) => theme.black};
  border-bottom: 3px inset ${({ theme }) => theme.primaryColor};

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &::before {
    display: inline-block;
    content: "";
    height: 12px;
    width: 12px;
    margin-right: 12px;
    border-bottom: 3px solid currentColor;
    border-right: 3px solid currentColor;
    transform: rotate(-45deg);
  }

  &[aria-expanded="true"]::before,
  &[aria-selected="true"]::before {
    transform: rotate(45deg);
  }

  &:hover,
  &:active {
    cursor: pointer;
    background-color: ${({ theme }) => theme.primaryColor};
    color: ${({ theme }) => theme.primaryColorLight};
  }
`;

export const STARAccordionItemPanel = styled(AccordionItemPanel)`
  background-color: ${({ theme }) => theme.lightGray};
  color: ${({ theme }) => theme.black};
  font-weight: 400;
  padding: 1rem;
  animation: fadein 0.35s ease-in;

  div {
    margin-bottom: ${({ theme }) => theme.pdDefault};
  }

  header {
    font-family: ${({ theme }) => theme.fntSerif};
    font-size: clamp(1rem, 1.25rem, 1.5rem);
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 700;
  }

  strong {
    color: ${({ theme }) => theme.primaryColor};
  }

  p {
    font-family: ${({ theme }) => theme.fntSans};
    margin: 0;
    margin-top: ${({ theme }) => theme.pdDefault};
    padding: 0;
  }

  p::first-line {
    text-transform: none;
    font-family: ${({ theme }) => theme.fntSans};
    color: initial;
    font-size: initial;
  }
`;
