import styled from "styled-components";

export const StyledHeader = styled.div`
  width: 100%;
  background-color: ${({ bgColor, theme }) => {
    if (bgColor === "green") return theme.secondaryColorLight;
    if (bgColor === "orange") return theme.novaColorLight;
    if (bgColor === "blue") return theme.galaxyColorLight;
    if (bgColor === "purple") return theme.primaryColor;
    if (bgColor === "lightPurple") return theme.primaryColorLight;
    return theme.contentBackground;
  }};
  background-image: url("${({ bgImage }) => bgImage}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${({ bgPosition }) => bgPosition};

  /* Blend Mode Values /
    normal:       This is default. Sets the blending mode to normal	
    multiply:     Sets the blending mode to multiply	
    screen:       Sets the blending mode to screen	
    overlay:      Sets the blending mode to overlay	
    darken:       Sets the blending mode to darken	
    lighten:      Sets the blending mode to lighten	
    color-dodge:	Sets the blending mode to color-dodge	
    color-burn:   Sets the blending mode to color-burn	
    difference:   Sets the blending mode to difference	
    exclusion:    Sets the blending mode to exclusion	
    hue:          Sets the blending mode to hue	
    saturation:   Sets the blending mode to saturation	
    color:        Sets the blending mode to color	
    luminosity:   Sets the blending mode to luminosity 
  */

  background-blend-mode: ${({ blendMode }) => blendMode};
  padding: 1rem 0;

  & > * {
    padding: ${({ theme }) => theme.pdDefault};
  }
`;

export const HeaderTitle = styled.h1`
  display: ${({ showTitle }) => {
    if (showTitle === "true") return "inherit";
    return "none";
  }};
  font-family: ${({ theme }) => theme.fntSerif};
  font-size: clamp(1.25rem, 5vw + 1rem, 3rem);
  /* font-weight: 600; */
  text-align: center;
  margin-top: 1rem;
  /* margin-bottom: 1rem; */
  text-shadow: 0.125rem 0.125rem 0.125rem ${({ theme }) => theme.black};
  -webkit-text-stroke: 0.075rem ${({ theme }) => theme.white};
  letter-spacing: 0.125rem;
  color: ${({ theme }) => theme.primaryColor};
`;
