import styled from "styled-components";

export const StyledLayout = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    /* grid-template-columns: 1fr; */
    height: 100vh;
    width: 100%;
    position: relative;
    overflow-x: hidden;
`