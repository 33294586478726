import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "./pages/Home";
import ProgramsPage from "./pages/Programs";
import CalendarPage from "./pages/Calendar";
import LocationsPage from "./pages/Locations";
import AboutPage from "./pages/About";
import SPCalendarPage from "./pages/SPCalendar";
import SPCalWPPage from "./pages/SPCalWP";
import SPCalRAPage from "./pages/SPCalRA";
import SPCalEDPage from "./pages/SPCalED";
import SummerInfoPage from "./pages/SummerInfo";

import GlobalStyles from "./styles/Global";
import theme from "./styles/Theme";
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import Layout from "./components/Layout/Layout";
import NavBar from "./components/NavBar/NavBar";
import './App.scss';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <GlobalStyles />
          <NavBar />
          <Body>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/programs" component={ProgramsPage} />
              <Route path="/locations" component={LocationsPage}/>
              <Route path="/about-star" component={AboutPage} />
              <Route path="/calendar" component={CalendarPage} />
              <Route path="/summer-preview-eu" component={SPCalendarPage} />
              <Route path="/summer-preview-wp" component={SPCalWPPage} />
              <Route path="/summer-preview-ra" component={SPCalRAPage} />
              <Route path="/summer-preview-ed" component={SPCalEDPage} />
              <Route path="/summer-info" component={SummerInfoPage} />
            </Switch>
          </Body>
          <Footer />
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
