import styled from "styled-components";

export const Strong = styled.strong`
  font-weight: 700;
  color: ${({ theme }) => theme.primaryColor};
`;

export const Important = styled.em`
  color: ${({ theme }) => theme.important};
  font-weight: 700;
`;

export const A = styled.a`
  color: ${({ theme }) => theme.primaryColor};
  padding: ${({ theme }) => theme.pdDefault};
`;

export const H1 = styled.h1`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fntSerif};
  font-size: clamp(1.25rem, 1.5rem, 2rem);
  padding: ${({ theme }) => theme.pdDefault};
`;

export const H2 = styled.h2`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fntSerif};
  font-size: clamp(1rem, 1.25rem, 1.75rem);
  padding: ${({ theme }) => theme.pdDefault};
`;

export const Box = styled.div`
  background-color: #fafafa;
  padding: calc(${({ theme }) => theme.pdDefault} * 2);
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
`;

export const QuickGrid = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 1rem;

  @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
    grid-auto-flow: column;
  }
`;
