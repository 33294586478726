import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import googleCalendarPlugin from "@fullcalendar/google-calendar";
// import interactionPlugin from "@fullcalendar/interaction";
import { Popover } from "bootstrap";
import { CalWrapper } from "./SPCalRA.style";

let popoverInstance = null;

// const API_KEY = "AIzaSyCcNHBgzJ5maW7Kf-CMQTLdkl9s8ZV_M3g";
// const calendarId = "starsacramento.org_qca4hlbv93lr62ka7n0ne1l6uc@group.calendar.google.com";

const SPCalRA = () => {
  const handleMouseEnter = (info) => {
    if (info.event.extendedProps) {
      console.info(info.event.extendedProps);
      popoverInstance = new Popover(info.el, {
        // asttachements: info.event.extendedProps.attachments.title,
        title: info.event.extendedProps.description,
        content: info.event.extendedProps.location,
        // colorId: info.event.extendedProps.background,
        html: true,
        placement: "auto",
        trigger: "hover",
        container: "body",
      });

      popoverInstance.show();
    }
  };

  const handleMouseLeave = () => {
    if (popoverInstance) {
      popoverInstance.dispose();
      popoverInstance = null;
    }
  };

  return (
    <CalWrapper>
      <FullCalendar
        plugins={[dayGridPlugin, googleCalendarPlugin]}
        googleCalendarApiKey="AIzaSyCL2TjqSpRRfU-gl1asTltqEbl6gTkhdaI"
        initialView="dayGridMonth"
        initialDate="2024-06-01"
        // eventColor="orange"
        // eventOrder="allDay"
        // eventClick={false}
        // eventOrderStrict={true}
        eventDisplay="block"
        displayEventTime={true}
        // height= "300px"
        // selectable={true}
        eventMouseEnter={handleMouseEnter}
        eventMouseLeave={handleMouseLeave}
        eventSources={[
          {
            googleCalendarId:
              "c_2a5babae4f420ba535aea6b6ac831a76bc71ca689806ab7385d1e9c154b6f748@group.calendar.google.com",
            color: "#ff8723",
            backgroundColor: "#ff8723",
            textColor: "black",
          },
        ]}
        eventClick={[
          function (info) {
            info.jsEvent.preventDefault(); // don't let the browser navigate

            // if (info.event.url) {
            //   window.open(info.event.url);
            // }
          },
        ]}
      />
    </CalWrapper>
  );
};

export default SPCalRA;
