const color = {
  blue: "#289BD6",
  lightBlue: "#C9E6F5",
  purple: "#703895",
  purpleAlpha: '#70389560',
  lightPurple: "#DBCDE4",
  green: "#6CC069",
  lightGreen: "#DAEFD9",
  orange: "#F26522",
  lightOrange: "#FCD8C8",
  red: "#b10000",

  black: "#1A1A1A",
  white: "#FAFAFA",
  offWhite: "#F1F1F1",
  lightGray: "#E6E6E6",
  pureWhite: "#FFFFFF",
};

// Fonts Setup
const fonts = {
  // fontSans: "'Roboto', sans-serif",
  fontSans: "'Red Hat Text', sans-serif",
  fontSerif: "'Bree Serif', serif",
  fontHandA: "'Rancho', cursive",
  fontHandB: "'Itim', cursive",
  fontHandC: "'Caveat Brush', cursive",
};

// these are exported so that jss can use them elsewhere, but we can keep them consistent/ change them as needed.
// This may fall into disuse though as we explore other methods of responsive design
const breakpoints = {
  small: 0,
  medium: "42em",
  large: "62em",
  ultra: "82em",
};

// this is the exported theme
const theme = {
  background: color.white,
  contentBackground: color.offWhite,
  navBackground: color.blue,
  navBackgroundHover: color.lightBlue,
  footerBackground: color.blue,
  // probably going to deprecate this language in favor of our 4 color system
  primaryColor: color.purple,
  //primaryColorHover: colorMod(color.purple).darken(0.2).hex(),
  primaryColorAlpha: color.purpleAlpha,
  primaryColorLight: color.lightPurple,
  secondaryColor: color.green,
  secondaryColorHover: color.lightGreen,
  // maybe named after each program type?  but then what if we add more program types, more colors?
  galaxyColor: color.blue,
  galaxyColorLight: color.lightBlue,
  campsColor: color.purple,
  campsColorLight: color.lightPurple,
  preschoolColor: color.green,
  preschoolColorLight: color.lightGreen,
  novaColor: color.orange,
  novaColorLight: color.lightOrange,

  black: color.black,
  white: color.white,
  pureWhite: color.pureWhite,
  lightGray: color.lightGray,
  lightText: color.white,
  errorColor: "#ff6622",
  errorColorLight: "#ffeeee",
  sectionAccent: "lightGray",
  important: color.red,

  // Fonts
  fontTitle: fonts.fontSerif,
  h: fonts.fontSerif,
  fontCheckBox: fonts.fontSerif,
  fntSans: fonts.fontSans,
  fntSerif: fonts.fontSerif,
  fntHandA: fonts.fontHandA,
  fntHandB: fonts.fontHandB,
  fntHandC: fonts.fontHandC,

  // Buttons
  btnPrimary: color.lightBlue,
  btnPrimaryHover: color.blue,
  btnPrimaryBorder: color.blue,
  btnPrimaryBorderHover: color.white,
  btnPrimaryDropShadow: color.lightBlue,
  btnFont: fonts.fontSerif,

  // Forms
  frmPrimaryBorder: color.blue,
  frmPrimaryFont: fonts.fontSerif,

  // Breakpoints
  bkpntSmall: breakpoints.small,
  bkpntMedium: breakpoints.medium,
  bkpntLarge: breakpoints.large,
  bkpntUltra: breakpoints.ultra,

  // Padding
  pdDefault: '.5rem',
};

export default theme;


