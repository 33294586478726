import styled from "styled-components"

export const StyledSection = styled.section`
    padding: ${({ theme }) => theme.pdDefault};
    box-shadow: 0 0 2px ${({ theme }) => theme.sectionAccent};
    border-radius: 0 12px;
    background-color: ${({ theme }) => theme.white};
    max-width: 90vw;
    
    p {
        text-align: left;
        margin-top: .5rem;
        margin-bottom: .5rem;
        line-height: 1.25;
    }

    ul {
        list-style: square;
        list-style-position: outside;
        margin: ${({ theme }) => theme.pdDefault} calc(${({ theme }) => theme.pdDefault} * 3);
    }

    li {
        line-height: 1.5;
    }
`

export const Title = styled.h1`
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fntSerif};
    font-size: calc(${({ titleSize }) => titleSize} / 1.25);

    @media (min-width: ${({ theme }) => theme.bkpntMedium}) {
        font-size: ${({ titleSize }) => titleSize};
    }
`